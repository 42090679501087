import React from "react";
import NewMarket from '../components/NewMarket';
import MarketList from '../components/MarketList';
import {API,graphqlOperation} from 'aws-amplify';
import {searchMarkets} from '../graphql/queries'
class HomePage extends React.Component {
  state = {
    searchTerm:'',
    searchResults:[],
    isSearching:false
  };
  handleSearchChange= searchTerm=>this.setState({searchTerm})
  handleClearSearch=()=>this.setState({searchTerm:'',searchResults:[]})
  handleSearch=async event=>{
    
    try{
      event.preventDefault();
      this.setState({isSearching:true})
      const result = await API.graphql(graphqlOperation(searchMarkets,{
        filter:{
          or:[
            {name:{match:this.state.searchTerm}},
            {owner:{match:this.state.searchTerm}},
            {tags:{match:this.state.searchTerm}}
            ]
        },
        sort:{
          field: 'createdAt',
          direction:'asc'
        }
      }))
      
      this.setState({
        searchResults:result.data.searchMarkets.items,
        isSearching:false
      })
      //console.log({result})
    }catch(err){
      console.log(err)
    }
  }
  render() {
    return (
      <>
        <NewMarket 
          handleSearchChange={this.handleSearchChange}
          isSeaching={this.state.isSearching}
          handleClearSearch={this.handleClearSearch}
          searchTerm={this.state.searchTerm}
          handleSearch={this.handleSearch}
        />
        <MarketList searchResults={this.state.searchResults}/>
      </>
    )
  }
}

export default HomePage;
