import React from "react";
import {Storage,Auth,API,graphqlOperation} from 'aws-amplify';
import {createProduct} from '../graphql/mutations'
// prettier-ignore
import {PhotoPicker} from 'aws-amplify-react'
import { Form, Button, Input, Notification, Radio, Progress } from "element-react";
import aws_exports from '../aws-exports';
import {convertDollarsToCents} from '../utils'
const initialState ={
  description:'',
  price:"",
  shipped:false,
  imagePreview:'',
  image:'',
  isUploading:false,
  percentUploaded:0
}
class NewProduct extends React.Component {
  
  state = {...initialState};
  handleAddProduct = async ()=>{
    try{
      this.setState({isUploading:true})
      const visibility = 'public';
      const {identityId} = await Auth.currentCredentials()
      const filename = `/${visibility}/${identityId}/${Date.now()}-${this.state.image.name}`
      
      const uploadedFile = await Storage.put(filename,this.state.image.file,{
        contentType:this.state.image.type,
        progressCallback: progress => {
         // console.log(progress)
          const percentUploaded = Math.round(100*progress.loaded/progress.total)
          //console.log(percentUploaded)
          this.setState({percentUploaded})
        }
        
      })
      
      const file = {
        key:uploadedFile.key,
        bucket:aws_exports.aws_user_files_s3_bucket,
        region:aws_exports.aws_project_region
      }
      
      const input = {
        productMarketId:this.props.marketId,
        description:this.state.description,
        shipped:this.state.shipped,
        price:convertDollarsToCents(this.state.price),
        file
      }
      console.log(input)
      const result = await API.graphql(graphqlOperation(createProduct,{input}))
      console.log(result)
      Notification({
        title:'Success',
        message:"Product successfully created",
        type:'success'
      })
      this.setState({...initialState})
    }catch(err){
      console.error(err)
    }
    }
  render() {
    return (
      <div className='flex-center'>
        <h2 className='header'>Add new Product</h2>
        <div>
          <Form className='market-header'>
            <Form.Item label='Add Product Description'>
              <Input
                type='text'
                icon='information'  
                placeholder='Description'
                value={this.state.description}
                onChange={description=>this.setState({description})}
              />
              
            </Form.Item>
            <Form.Item label='Set Product Price'>
              <Input
                type='number'
                icon='plus'  
                placeholder='Price ($USD)'
                value={this.state.price}
                onChange={price=>this.setState({price})}
              />
              
            </Form.Item>
            
            <Form.Item label='Is the Product Shipped or Emailed to the Customer'>
              <div className='text-center'>
                <Radio
                  value='true'
                  checked={this.state.shipped===true}
                  onChange={()=>this.setState({shipped:true})}
                >
                  Shipped
                </Radio>
                
                
                <Radio
                  value='false'
                  checked={this.state.shipped===false}
                  onChange={()=>this.setState({shipped:false})}
                >
                  Emailed
                </Radio>
              </div>
              
            </Form.Item>
            
            {this.state.imagePreview&&(
              <img
                className='image-preview'
                src={this.state.imagePreview}
                alt='Product Preview'
              />
            
            )}
            {this.state.percentUploaded>0&&
              <Progress
                type='circle'
                className='progress'
                percentage={this.state.percentUploaded}
                status='success'
              />
              
            }
            <PhotoPicker
              title='Product Image'
              preview='hidden'
              onLoad={url=>this.setState({imagePreview:url})}
              onPick={file=>this.setState({image:file})}
              theme={{
                formContainer:{
                  margin:0,
                  padding:'0.8em'
                },
                formSection:{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                },
                sectionBody:{
                  margin:0,
                  width:'250px'
                },
                sectionHeader:{
                  padding:'0.2em',
                  color:'var(--darkAmazonOrange)'
                },
                photoPickerButton:{
                  display:"none"
                }
              }}
            
            
            />
            
            <Form.Item>
              <Button
                type='primary'
                onClick={this.handleAddProduct}
                disabled={!this.state.image||!this.state.price||!this.state.description||this.state.isUploading}
                loading={this.state.isUploading}
              >
                {this.state.isUploading ? 'Uploading...' : 'Add Product'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      
      
      )
  }
}

export default NewProduct;
