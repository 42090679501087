import React from 'react';
import { Authenticator, AmplifyTheme } from 'aws-amplify-react';
import {API,graphqlOperation,Auth, Hub} from 'aws-amplify';
import {getUser} from './graphql/queries'
import {registerUser} from './graphql/mutations'
import createBrowserHistory from 'history/createBrowserHistory'
import { Router, Route } from 'react-router-dom'
import './App.css';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import MarketPage from './pages/MarketPage';
import Navbar from './components/Navbar'


export const history = createBrowserHistory();
export const UserContext = React.createContext();
class App extends React.Component {
  state = {
    user: null,
    userAttributes:null
  };
  
  componentDidMount(){

    this.getUserData();
    Hub.listen('auth',this,'onHubCapsule');
  }
  
  getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    user ? this.setState({user},()=>this.getUserAttributes(this.state.user)): this.setState({user:null})
  }
  
  getUserAttributes = async authUserData =>{
    const attributesArr = await Auth.userAttributes(authUserData)
    const attributesObj = Auth.attributesToObject(attributesArr)
    this.setState({userAttributes:attributesObj})
  }
  
  onHubCapsule = capsule => {
    switch(capsule.payload.event){
      case "signIn":
        console.log('signed in')
        this.getUserData();
        this.registerNewUser(capsule.payload.data);
        break;
      case "signUp":
        console.log('signed up')
        break;
      case 'signOut':
        console.log('signed out')
        this.setState({user:null})
        break;
      default:
        return
    }
  }
  
  registerNewUser = async signInData =>{
    const getUserInput = {
      id:signInData.signInUserSession.idToken.payload.sub
    }
    const {data} = await API.graphql(graphqlOperation(getUser,getUserInput))
    // if we cant get a user (meaning the user hasn't been registerd before)
    // then we execute registeUser
    console.log(data)
    if (!data.getUser){
      try{
        const registerUserInput = {
          ...getUserInput,
          username:signInData.username,
          email:signInData.signInUserSession.idToken.payload.email,
          registered:true
        }
        
        await API.graphql(graphqlOperation(registerUser,{
          input:registerUserInput
        }))
        
      }catch(err){
        console.error("Error registering new user",err)
      }
    }
  }
  handleSignout = async ()=>{
    try {
        await Auth.signOut()    
    }catch(err){
        console.error('error signing out user' + err)
    }
    
  }


  render(){
    const {user,userAttributes} = this.state;
    return user ? 
            ( 
              <UserContext.Provider value={{user,userAttributes}}>
              <Router history={history}>
                <>
                <Navbar user={user} handleSignout={this.handleSignout}/>
                  <div className='app-container'>
                    <Route exact path='/' component={HomePage}/>
                    <Route path='/profile' component={()=><ProfilePage user={user} userAttributes={userAttributes}/>} />
                    <Route path="/market/:marketId" component={({match})=><MarketPage user={user} userAttributes={userAttributes} marketId = {match.params.marketId}  />} />
                  
                  </div>
                </>
              </Router>
              </UserContext.Provider>
            ) : 
            (<Authenticator theme={theme}/>)
  }
 
}

const theme = {
  ...AmplifyTheme,
  navBar:{
    ...AmplifyTheme.navBar,
    backgroundColor:"#ffc0cb"
  },
  button:{
    ...AmplifyTheme.button,
    backgroundColor:"var(--amazonOrange)"
  },
  sectionBody:{
    ...AmplifyTheme.sectionBody,
    padding:'5px'
  },
  sectionHeader:{
    ...AmplifyTheme.sectionHeader,
    backgroundColor:"var(--squidInk)"
  }
}

//export default withAuthenticator(App,true,[],null,theme);
export default App;