// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:33a17067-625f-4c18-8168-1966f0469b93",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LQYlv0O5t",
    "aws_user_pools_web_client_id": "22aacahmqrgrvhpg4jmra0jd98",
    "aws_appsync_graphqlEndpoint": "https://o7sozwwocndm5hxldawix3355e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "orderlambda",
            "endpoint": "https://feoza3dlhi.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "lisunmeec54604ab6c84697b19addc7e6ccc0cc",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "lisunme-20190208213258-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d12ea4yuqlniuz.cloudfront.net"
};


export default awsmobile;
