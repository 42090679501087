import React from "react";
import {S3Image} from 'aws-amplify-react'
// prettier-ignore
import { Notification, Popover, Button, Dialog, Card, Form, Input, Radio } from "element-react";
import {convertCentsToDollar,convertDollarsToCents} from '../utils';
import {UserContext} from '../App'
import PayButton from './PayButton'
import {updateProduct,deleteProduct} from '../graphql/mutations'
import {API, graphqlOperation} from 'aws-amplify';
import {Link} from 'react-router-dom'
class Product extends React.Component {
  state = {
    updateProductDialog:false,
    description:'',
    price:'',
    shipped:false,
    deleteProductDialog:false
  };
  handleUpdateProduct = async productId=>{
    try{
      const {description,price,shipped} = this.state
      const input = {
        id:productId,
        description,
        shipped,
        price:convertDollarsToCents(price)
        
      }
      await API.graphql(graphqlOperation(updateProduct,{input}))
      //console.log({result})
      this.setState({updateProductDialog:false})
      
      Notification(
        {
          title:"Success",
          message:"Product successfullty updated!",
          type:'success'
        }
        )
    }catch(err){
      console.error(`Failed to update ${err}`)
    }
    
  }
  
  handleDeleteProduct = async productId =>{
    try{
      this.setState({deleteProductDialog:false});
      const input = {
        id:productId
      }

      await API.graphql(graphqlOperation(deleteProduct,{input}))
      
      Notification(
        {
          title:"Success",
          message:"Product successfullty deleted!",
          type:'success'
        }
        )
        
        
    }catch(err){
      console.error(err)
    }
  }
  
  render() {
    const {updateProductDialog,deleteProductDialog} = this.state
    const {product} = this.props;
    //console.log(product)
    return (
      
      <UserContext.Consumer>
      {({user,userAttributes})=>{
        const isProductOwenr = user && user.attributes.sub===product.owner;
        const isEmailVerified = userAttributes && userAttributes.email_verified;
        return (
          <div className='card-container'>
            <Card bodyStyle={{padding:0,minWidth:'200px'}}>
              {
              <S3Image 
                imgKey={product.file.key}
                theme={{
                  photoImg:{ maxWidth:'100%',maxHeight:'100%'}
                }}
              />
              }
              <div className='card-body'>
                <h3 className='m-0'>{product.descrption}</h3>
                <div className='items.center'>
                  <img
                    src={`https://icon.now.sh/${product.shipped?"markunread_mailbox":"mail"}`}
                    alt='Shipping Icon'
                    className='icon'
                  />
                  {product.shipped?"Shipped":"Emailed"}
                </div>
                
                <div className='text-right'>
                  <span className='mx-1'>
                    
                    $ {convertCentsToDollar(product.price)}
                  </span>
                  {isEmailVerified?(!isProductOwenr && (
                    <PayButton product={product} userAttributes={userAttributes}/>
                    )):(
                    <Link to='/profile' className='link'>
                      Verify your email
                    </Link>
                    )
                  }
                </div>
              </div>
            </Card>
            <div className='text-center'>
              {isProductOwenr && (
                <>
                  <Button
                    type='warning'
                    icon='edit'
                    className='m-1'
                    onClick={()=>this.setState({
                      updateProductDialog:true,
                      description:product.description,
                      shipped:product.shipped,
                      price:convertCentsToDollar(product.price)
                      
                      
                    })}
                  />
                  
                  <Popover
                    placement='top'
                    witdh='160'
                    trigger='click'
                    visible={deleteProductDialog}
                    content={
                      <>
                        <p>Do you want to delete this</p>
                        <div className='text-right'>
                          <Button size='mini' type='text' className='m-1' onClick={()=>this.setState({deleteProductDialog:false})}>Cancel</Button>
                          <Button type='primary' size='mini' className='m-1' onClick={()=>this.handleDeleteProduct(product.id)}>Delete</Button>
                        </div>
                      </>
                    }
                  >
                    <Button
                      onClick={()=>this.setState({deleteProductDialog:!deleteProductDialog})}
                      type='danger'
                      icon='delete'
                    />
                  </Popover>
                </>
                
              )}
            
            </div>
            <Dialog
              title='Update Product'
              size='large'
              customClass='dialog'
              visible={updateProductDialog}
              onCancel={()=>this.setState({updateProductDialog:false})}
            >
              <Dialog.Body>
                <Form labelPosition='top'>
                <Form.Item label='Update Description'>
                  <Input
                    icon='information'
                    placeholder='Product Description'
                    value={this.state.description}
                    trim={true}
                    onChange={description=>this.setState({description})}
                  />
                    
                  </Form.Item>
                  <Form.Item label='Update Price'>
                    <Input
                      type='number'
                      icon='plus'  
                      placeholder='Price ($USD)'
                      value={this.state.price}
                      onChange={price=>this.setState({price})}
                    />
                    
                  </Form.Item>
                  
                  <Form.Item label='Update Shipping'>
                    <div className='text-center'>
                      <Radio
                        value='true'
                        checked={this.state.shipped===true}
                        onChange={()=>this.setState({shipped:true})}
                      >
                        Shipped
                      </Radio>
                      
                      
                      <Radio
                        value='false'
                        checked={this.state.shipped===false}
                        onChange={()=>this.setState({shipped:false})}
                      >
                        Emailed
                      </Radio>
                    </div>
                    
                  </Form.Item>
                  
                
                </Form>
              </Dialog.Body>
              
              <Dialog.Footer>
                <Button
                  onClick ={()=>this.setState({updateProductDialog:false})}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  onClick ={()=>this.handleUpdateProduct(product.id)}
                >
                  Update
                </Button>
              </Dialog.Footer>
            
            </Dialog>
            
            
          </div>
        )
      }}
    
      
      
      
      
      </UserContext.Consumer>
    )
  }
}

export default Product;
