import React from "react";
import {API ,graphqlOperation} from 'aws-amplify';
import { Loading, Tabs, Icon } from "element-react";
import {Link} from 'react-router-dom';
import NewProduct from '../components/NewProduct'
import Product from '../components/Product'
import {formatProductData} from '../utils'
import {onCreateProduct,onUpdateProduct,onDeleteProduct} from '../graphql/subscriptions'
const getMarket = `query GetMarket($id: ID!) {
  getMarket(id: $id) {
    id
    name
    products(sortDirection:DESC,limit:999) {
      items {
        id
        description
        price
        shipped
        owner
        createdAt
        file {
          key
        }
      }
      nextToken
    }
    tags
    owner
    createdAt
  }
}
`;

class MarketPage extends React.Component {
  state = {
    market:null,
    isLoading:true,
    isMarketOwner:false,
    isEmailVerified:false
  };
  componentDidMount(){
    this.handleGetMarket()
    this.createProductListner = API.graphql(graphqlOperation(onCreateProduct))
      .subscribe({
        next:productData=>{
          const createdProduct = productData.value.data.onCreateProduct;
          const prevProducts = this.state.market.products.items.filter(
            item=>item.id!==createdProduct.id)
          const updatedProducts = [createdProduct,...prevProducts]
          const market = {...this.state.market}
          market.products.items = updatedProducts;
          this.setState({market})
        }
      })
      
      this.deleteProductListerner = API.graphql(graphqlOperation(onDeleteProduct))
      .subscribe({
        next:productData=>{
          const deletedProduct = productData.value.data.onDeleteProduct;
          const updatedProducts = this.state.market.products.items.filter(
            item=>item.id!==deletedProduct.id)
          const market = {...this.state.market}
          market.products.items = updatedProducts;
          this.setState({market})
        }
      })
      
      
      this.updateProductListener = API.graphql(graphqlOperation(onUpdateProduct))
        .subscribe({
          next:productData=>{

          const updatedProduct = productData.value.data.onUpdateProduct
          const updatedProducts = this.state.market.products.items.map(product=>(product.id===updatedProduct.id)?updatedProduct:product)
          const market = {...this.state.market}
          market.products.items = updatedProducts;
          this.setState({market})
          
          }
        })
  }
  
  componentWillUnmount(){
    this.createProductListner.unsubscribe()
    this.updateProductListener.unsubscribe()
    this.deleteProductListerner.unsubscribe()
  }
  handleGetMarket = async () => {
    const input = {
      id:this.props.marketId
    }
    const result = await API.graphql(graphqlOperation(getMarket,input));
    //console.log({result})
    this.setState({market:result.data.getMarket,isLoading:false},this.checkMarketOwner,this.checkEmailVerified())
  }
  checkMarketOwner = ()=>{
    const {user} = this.props
    const {market}=this.state;
    if(user){
      this.setState({isMarketOwner:user.username===market.owner})
    }
  }
  
  checkEmailVerified = ()=>{
    const {userAttributes} = this.props;
    if(userAttributes){
      this.setState({isEmailVerified:userAttributes.email_verified})
    }
    
  }
  render() {
    const {market,isLoading,isMarketOwner,isEmailVerified} = this.state;
    
    return isLoading ? (
      <Loading fullscreen={true}/>
      ) :(
        <>
          <Link className='link' to='/'>
            Back to Market List
          </Link>
          
          
          
          <span className='items-center pt-2'>
            <h2 className='mb-mr'>{market.name}</h2>-{market.owner}
          
          </span>
        
        
        
          <div className='item-center pt-2'>
            <span style={{color:'var(--lightSquidInk)',paddingBotton:'1em'}}>
              <Icon name='date' className='icon'/>
              {formatProductData(market.createdAt)}
            </span>
          </div>
          <Tabs type='border-card' value={isMarketOwner?"1":"2"}>
            {isMarketOwner && (
              <Tabs.Pane
                label={
                  <>
                    <Icon name='plus' className='icon'/>
                    Add Product
                  </>
                }
                name='1'
              >
              
                {isEmailVerified?(
                  <NewProduct marketId={this.props.marketId}/>):(
                  <Link to='/profile' className='header'>
                    Verified Your Email Before Adding Product
                  </Link>
                )}
              </Tabs.Pane>
            )}
            
            <Tabs.Pane
              label={
                <>
                  <Icon name='menu' className='icon'/>
                  Products({market.products.items.length})
                </>
              }
              name = '2'
            >
              <div className='product-list'>
                {market.products.items.map(product=>(
                  <Product key={product.id} product={product} />
                ))}
              </div>
            </Tabs.Pane>
          </Tabs>
        </>
      )
  }
}

export default MarketPage;
